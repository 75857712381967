$template-border-color: #f1f1f1;


@mixin labelStyle {
  color: $grey;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 2px;
  white-space: nowrap;
  text-transform: uppercase;
}



body.myTemplates {
  table { font-family: $reg-font; }
  .sidebar-content > ul > li > ul { display: block; }

  @include media($mobile-menu-break) {
    main.articles-main > article {
      display: flex;
      flex-direction: column;
      min-height: 100vh;

      > .article-body { flex-grow: 1; }
    }
  }
}
.template-title {
  margin-bottom: 1.25em;
  font-family: $reg-font;
  font-weight: 599;
  font-size: 24px;
  color: $content-font-color;
}
.template-heading {
  padding: 4px 20px 2px;
  font-size: 14px;
  background: $main-color;
  color: $white;
  font-family: 'Panton-SemiBold', Arial, Helvetica, sans-serif;
}

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}

.template-block {
  table {
    height: max-content;
    width: 100%;
    margin: 0;
    table-layout: auto;


    tr { position: relative; background: unset; }

    td, th {
      // border: 2px solid $white;
    }

    td {
      position: relative;
      background: $template-border-color;
      vertical-align: top;
      border: 2px solid #e8e9ea;
      padding: 0;
      // height: 100%;
      font-family: Arial, serif !important;

      &.cell--view-only {
        background: unset;
      }

      @-moz-document url-prefix() {
        height: 100%;
      }

      @supports (-moz-appearance:none) {
        height: 100%;
      }

      .vertical-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background: $grey;
        transform: translate(-1em, -1em);
        font-weight: bold;
        > span {
          transform: rotate(-90deg);
        }
      }
    }

    tbody {
      td {
        // border: 2px solid #F5F5F5;
      }
    }
    th {
      position: relative;
      padding: 1em;
      background: #dedede;
      color: $content-font-color;
      font-family: Arial, serif !important; //$reg-font;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      // letter-spacing: 2px;
      border: none;
      border-left: 2px solid $white;
      // text-align: center;

      &:first-child {
        border: none;
      }

      .tooltip {
        position: absolute;
        top: -2px;
        right: 0;

        &__button {
          svg {
            fill: currentColor;
            width: 26px;
            height: 26px;
            opacity: 0.5;
          }

          &:hover {
            svg {
              opacity: 0.75;
            }
          }
        }

        &__content {
          text-transform: none;
          letter-spacing: 0;
          text-align: left;
          margin-left: -4px;
          margin-top: -10px;
        }
      }
    }

    tbody {
      font-size: 14px;

      .cell {
        &--has-inner {
          padding: 0;
        }


        p {
          margin: 0 0 1.25em;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .template-table {

    &--inner {
      td, th {
        border-left: none;
        border-right: none;
      }
      td {
        border-bottom: none;
      }

      th {
        border-bottom: none;
        font-weight: 600;
        background-color: darken($color: #EBEBEB, $amount: 5%);
        font-size: 12px;
        // text-transform: unset;
        letter-spacing: 0;
        line-height: 1;
        color: #666;
        padding: 0.75em 0.875em;
      }
    }

    &__cell {
      // padding-right: 15px;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;

      [contenteditable],textarea {
        white-space: pre-wrap;
        height: 100%;
        border: none;
        resize: none;
        margin: 0;

        &:read-only,
        &:disabled {
          background: $grey;
        }

        &[name*="rating"] + .input-counter {
          display: none !important;
        }
      }

      [contenteditable=true],textarea {
        font-size: 15px;
        min-height: inherit;
        min-width: 40px;
        font-size: 14px;
        width: 100%;
        word-break: break-word;
        background: $white;

        &.ng-invalid {
          & ~ .validate {
            display: block;
          }
        }

        &:before {
          font-size: 1em;
        }

        &.ng-invalid {
          // border: 1px solid $red !important;
          background: rgba(255, 0, 0, 0.5) !important;

          &:focus {
            box-shadow: 0 0 1.125rem rgba($red, 0.5);
          }
        }

        &.ng-hide {
          + .input-counter {
            display: none !important;
          }
        }
      }

      .mce-edit-focus ~ .input-counter {
        display: block;
      }

      [contenteditable=false],
      [ng-bind-html] {
        padding: 1em;
      }

      [ng-bind-html] {
        .cell_heading {
          display: block;
          margin: -1.15em -1.4em;
          font-weight: 600;
          background-color: #dedede;
          font-size: 12px;
          color: #666;
          padding: 0.667em 0.875em;
          text-transform: uppercase;
        }
      }

      &-content {
        display: flex;
        flex-grow: 1;
        width: 100%;
        // padding: 10px;

        > div {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .tooltip {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        // display: none;

        &__button {
          svg {
            fill: lighten($main-color, 5);
            width: 32px;
            height: 32px;
          }

          &:hover {
            svg { fill: darken($main-color, 15); }
          }
        }

        &__content {
          top: calc(100% + 10px);
          &:before {
            margin-left: -10px;
          }
        }

      }
    }

    &__field {
      position: relative;
      height: 100%;
      min-height: 80px;
      user-select: auto;

      * { user-select: inherit; }

      &-value {
        padding: 1em 1.66667em;
        white-space: pre-wrap;
      }

      template-field {
        height: 100%;
        display: block;
        min-height: inherit;

       &::placeholder { color: #AAB3BE; }

        > div,
        .mce-content-body {
          height: 100%;
          min-height: inherit;
        }

        .mce-content-body { padding: 1em; }

      }

      > .mce-tinymce-inline {
        position: absolute;
        top: unset !important;
        bottom: 100%;
        z-index: 1;
      }

      .validate {
        display: block;
        font-size: 12px;
        padding: 5px 10px;
        top: 100%;
        z-index: 9;
      }

      .ng-invalid {
        .mce-content-body,
        textarea {
          box-shadow: $error-color 0px 0px 0.75rem;
        }
        &.ng-invalid-max-lines {
          .mce-content-body,
          textarea {
            box-shadow: $warning-color 0px 0px 0.75rem;
          }

          + p.validate {
            background-color: $warning-color;
            color: $dark-blue;
            &:before {
              border-bottom-color: $warning-color;
            }
          }
        }
      }
    }

    &__cell-legend {
      display: flex;
      height: 100%;

      > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        > span {
          margin: auto;
        }
      }

      [class*='__cell'] {
        flex-grow: 1;
        background: $template-border-color;
        // padding: 10px;
        margin: -12px -12px -11px;
        text-align: left;
        text-transform: initial;
        font-weight: normal;
      }
    }

    &__legend {
      &[colspan] {
        &:not([colspan='1']) {
          [class~='template-table__cell'] {
            min-height: 80px;
          }
        }
      }
    }

    &__set {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      [class*='table__block'] {
        flex: 1;

        > table {
          height: 100%;
        }
      }
    }
  }

  .select-holder {
    margin: 0;
    border-width: 1px;
    border-color: $grey;

    option {
      &.disabled {
        color: $grey;
      }
    }

    select {
      font-size: 1.0625em;
      font-weight: 600;
      padding: 0.411764705882353em 1.176470588235294em;
    }
  }
}

.progress-block {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  z-index: 0;

  li {
    padding: 0;
    margin: 0;
    flex: 1 1 auto;

    &.active {
      flex-grow: 0;
      [class*='__label'] {
        overflow: hidden;
        background: darken($color: $grey, $amount: 15);
        color: $white;
        overflow: visible;
        border-radius: 0 2px 2px 0;

        &:before {
          margin: 0;
          top: 0;
          left: calc(100% - 1px);
          width: 12px;
          height: 100%;
          background: darken($color: $grey, $amount: 15);
          transform: none;
          border: none;
          border-radius: 0;
          z-index: 1;
          clip-path: polygon(0 0, 0% 100%, 100% 50%);
        }

      }
      + li {
        [class*='__label'] {
          padding-left: 15px;
        }
      }
    }

    &:last-child {
      [class*='__label'] {
        &:before {
          display: none;
        }
      }
    }
  }

  &__label {
    position: relative;
    padding: 3px 5px 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #EBEBEB;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-family: $reg-font;
    font-weight: bold;
    letter-spacing: 1px;

    > span {
      font-size: 75%;
    }

    &:before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -6px;
      transform-origin: center;
      transform: rotate(45deg);
      z-index: 1;
      border-radius: 2px;
      border-top: 2px solid $white;
      border-right: 2px solid $white;
    }
  }
}


.template-docs {
  table {
    width: 100% !important;
    table-layout: auto;
    font-size: 14px;
    border: none !important;
    margin-bottom: 0 !important;

    tr {
      position: relative;
      background: none !important;
      border: none;
    }
    tbody {
      tr {
        td:first-child {
          background: #EBEBEB;
          font-weight: bold;
        }
      }
    }

    td {
      font-size: 14px;
      border: 1px solid #F5F5F5;
      background: $white;
      &[ng-click]:not([disable-click]) {
        cursor: pointer;
      }
    }

    th {
      position: relative;
      background: #E1E1E1;
      color: $content-font-color;
      font-family: $reg-font;
      font-weight: bold;
      padding: 12px 10px 12px 15px !important;
      font-size: 13px;
      text-align: left;
      border: 1px solid #F5F5F5;
      box-sizing: border-box;

      .tooltip {
        text-transform: none;
        letter-spacing: 0;
        position: absolute;
        top: calc(50% - 19px);
        right: 0;
        // transform: translateY(-50%);
        // z-index: 999;

        &__button {
          svg {
            fill: $main-color;
          }
        }
        &__content {
          margin-left: -4px;
          margin-top: -10px;
        }
      }

      @include media($mobile-menu-break-max) {
        &:last-child {
          .tooltip {
            &__content {
              left: unset;
              right: -100%;
              transform: none;

              &::before {
                display: none;
              }
            }
            &--open {
              [class*='__content'] {
                right: 5px;
              }
            }
          }
        }
      }
    }

    td,
    th {
      &.no-style {
        border: none !important;
        background: transparent !important;
      }
    }

    @include media($mobile-menu-break-max) {
      &.dataTable {
        tbody,
        thead {
          > .child {
            .dtr-title {
              .tooltip {
                display: none;
              }
            }

            .dtr-data { display: inline-block; }
          }

          td, th { padding: 10px 10px; }
        }
      }
    }
  }

  &__actions {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    > li {
      margin-left: 0;
      padding: 0;

      &:not(:first-child) {
        margin-left: 5px;
      }

      > span,
      > a {
        td & {
          color: $main-color;

          // &.primary {
          //   color: $main-color;

          //   &:hover {
          //     color: $dark-blue;
          //   }
          // }
        }

        // &:hover {
        //   color: $content-font-color;
        // }
      }
    }

    svg {
      fill: currentColor;
      width: 24px;
      height: 24px;
      &:not([class*='__close']) { pointer-events: none; }
    }

    .dropmenu-open .dropmenu-holder {
      top: 2.75rem;

      @include media($mobile-menu-break-max) { top: 0; .template-megaform__toolbar-buttons & { top: 2.75rem; } }
    }

    .dropmenu-holder {
      @include media($mobile-menu-break-max) { left: 0; right: unset; .template-megaform__toolbar-buttons & { left: unset; right: 0; } }
      ul {
        li {
          &:not(:first-child) {
            border-top: 1px solid #ddd;
          }
          a {
            display: block;
            padding: 1rem;
            background: transparent;
            font-size: 14px;
            color: unset;

            &:hover {
              color: $white;
            }

            &.link--active,
            &.link--disabled {
              pointer-events: none;
            }

            &.link--disabled {
              background: $grey;
              color: darken($color: $grey, $amount: 30)
            }
          }

          &[disabled] {
            a {
              background: #aaa !important;
            }
          }
        }
      }
    }

    @include media($mobile-menu-break-max) {
      .template-block [class*='__header'] &,
      .template-block [class*='__footer'] & {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-end;

        li:first-child {
          margin-right: auto;
        }
      }
    }
  }

  &__badge {
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  &__header {
    @include media($mobile-menu-break) {
      display: flex;
      justify-content: space-between;
    }

    @include media($mobile-menu-break-max) {
      [class$='__actions'] {
        display: flex;
        justify-content: flex-end;
      }
      .dropmenu-holder {
        position: absolute;
        top: calc(100% + 15px) !important;
        min-width: 120px;
        height: unset;
      }
    }
  }

  &__footer {
    margin-top: 1em;
    display: flex;
    justify-content: center;

    li {
      padding: 10px;
    }
    .btn {
      min-width: 17.5em;
    }

    [class$='__actions'] {
      margin: auto;
    }
  }

  > .dataTables_wrapper {
    padding-top: 0;

    @include media($mobile-menu-break-max) {
      overflow: auto;
    }

    .dataTable {
      &.no-footer {
        margin-bottom: 0;
      }

    }
  }

  &__progress {
    margin: 0 -15px;
    padding: 1.25em 15px 0;
    background: $white;
    border: 1px solid #EBEBEB;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
    @include media($mobile-menu-break) {
      padding: 2em 3.5em 1.5em;
      margin: 0.75em 0 1em;
    }

    &__title {
      margin: 0 0 1.75em;
      padding: 0;
      font-size: 17px;
      font-weight: 600;
    }

    &__steps {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;

      @include media($mobile-menu-break) {
        margin-left: -6em;
        margin-right: -5.25em;
      }

      > li {
        padding: 0;
        margin: 0;
        text-align: center;
        flex: 1;
        font-size: 14px;

        &:last-child {
          [class*='__item'] {
            &:before {
              opacity: 0;
            }
          }
        }

        &.disabled{
          pointer-events: none;
          [class*='__item'] {
            color: #AAB3BE;
            &:before{
              background-color: #EEF0F2;
            }
            &:after {
              background-color: #EEF0F2;
            }
          }
        }

        &.active {
          pointer-events: none;
          [class*='__item'] {
            &:before,
            &:after {
              z-index: 1;
            }
          }
          ~ li  [class*='__item']:after{
            background-image: url('#{$svg-path}Icons/Do Sm-2.svg?v=4');
            background-size: 12px;
            background-position: center;
            @include media($mobile-menu-break) {
              margin-top: -0.85em;
              background-size: 15px;
              background-position: center;
            }
          }

          ~ li.disabled {
            [class*='__item'] {
              color: #AAB3BE;


              &:before,&:after{
                background: #EEF0F2;
              }
              svg { filter: invert(74%) sepia(10%) saturate(299%) hue-rotate(173deg) brightness(95%) contrast(91%); }
            }
          }
          ~ li {
            [class*='__item']:before{
              background: #EEF0F2;
            }
          }
          ~ li:not(.disabled) {
            [class*='__link']{
              opacity:.5;
              &:hover{
                opacity:1;
              }
            }
          }

          span[class*='__item']:before { background: #EEF0F2; }
        }

        &:nth-child(1) {
          [class*='__item'] {
            color: #AAB3BE;
            &:hover {
              border-color:#AAB3BE !important;
            }
            &:before,
            &:after { background-color: #AAB3BE; }
            svg { filter: invert(74%) sepia(10%) saturate(299%) hue-rotate(173deg) brightness(95%) contrast(91%); }
          }
        }
        &:nth-child(2) {
          [class*='__item'] {
            color: $main-color;
            &:hover {
              border-color:$main-color !important;
            }
            &:before,
            &:after { background-color: $main-color; }
            svg { filter: invert(72%) sepia(98%) saturate(4745%) hue-rotate(164deg) brightness(100%) contrast(102%); }
          }
        }
        &:nth-child(3) {
          [class*='__item'] {
            color: #38444B;
            &:hover {
              border-color:#38444B !important;
            }
            &:before,
            &:after { background-color: #38444B; }
            svg { filter: invert(24%) sepia(13%) saturate(673%) hue-rotate(158deg) brightness(95%) contrast(89%); }
          }
        }
        &:last-child {
          [class*='__item'] {
            color: #7ED321;
            &:hover {
              border-color:#7ED321 !important;
            }
            &:before,
            &:after { background-color: #7ED321; }
            svg { filter: invert(63%) sepia(49%) saturate(652%) hue-rotate(46deg) brightness(103%) contrast(96%); }
          }
        }
      }
    }

    &__item {
      display: block;
      position: relative;
      font-weight: 600;


      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 8px;
        background: $main-color;
        transform: translateX(50%);
        margin-bottom: 1.5em;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 2.275em;
        width: 2.275em;
        border-radius: 50%;
        left: 50%;
        transition:transform .2s linear;
        top: 0;
        margin-left: -1em;
        margin-top: -0.70em;
        background: $main-color url('#{$svg-path}Icons/Tick Sm-2.svg') no-repeat center -1px;
        z-index: 2;

        @include media($mobile-menu-break) {
          margin-top: -0.85em;
          background-size: 40px;
          background-position: center -6px;
        }
      }

      &__status {
        display: block;
      }

      &__link {
        display: inline-block;
        position: relative;
        z-index: 3;
        transition:all .2s linear;
        border-bottom:2px solid transparent;
        &:hover{
          border-bottom:2px solid #ccc;
          transform: translateY(-2px)
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 150%;
          height: 70px;
          background: none;
          opacity: 0;
        }
      }
    }
  }

  &__info {
    font-size: 14px;
  }

  &__notes {
    font-size: 14px;

    &__block {
      min-width: 100vw;
      padding: 1.125em 1.5em 0;
      height: 100%;

      @include media($mobile-menu-break) {
        min-width: 568px;
      }
      @include media($mobile-menu-break-max) {
        display: flex;
        flex-direction: column;

        > * {
          width: 100%;
        }

        [class*='__footer'] {
          margin-top: auto;
        }
      }

      &--loading {
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($white, 0.25);
          z-index: 1;
        }

        &:after {
          content: '';
          display: block;
          animation: rotation 1s infinite linear;
          border: 2px solid rgba(#333, 0.325);
          border-radius: 50%;
          border-top-color: rgba(#000, 0.75);
          height: 3em;
          width: 3em;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -1.5em;
          margin-top: -1.5em;
          z-index: 2;
        }
      }
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid #EBEBEB;
      font-size: 14px;

      @include media($mobile-menu-break) {
        font-size: 17px;
      }

      svg[class*='__close'] {
        cursor: pointer;
        width: 38px;
        height: 38px;
        fill: $grey;
        @include media($mobile-menu-break) { margin: -10px -13px 0 0; }

        &:hover {
          fill: darken($grey, 20);
        }
      }

      .dropdown-title {
        font-weight: bold;
        display: flex;
        align-items: center;
        svg {
          margin-right: 5px;
          @include media($mobile-menu-break) {
            margin-right: 10px;
            width: 32px;
            height: 32px;
          }
        }
      }

      .note-owner {
        small {
          display: block;
          margin-bottom: 5px;
          text-transform: uppercase;
          color: #AAB3BE;
          letter-spacing: 2px;
          font-weight: 600;
        }

        strong {
          color: #38444B;
          font-weight: 600;
        }
      }

      @include media($tablet-max) {
        flex-wrap: wrap;
        position: relative;
        padding-bottom: 0;

        .dropdown-title {
          flex: 1 1 100%;
          max-width: 100%;
          font-size: 17px;
          justify-content: center;
          font-weight: 600;
          padding-bottom: 1em;

          svg { display: none; }
        }

        .note-owner {
          padding-top: 1.5em;
          padding-left: 4em;
          flex: 1 0 0;
          margin-left: -1.5em;
          margin-right: -1.5em;
          background-color: #F6F7F8;
          margin-bottom: -1px;

          > strong { font-size: 15px; }
        }

        .dropmenu-trigger__close {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(12px, -8px) !important;
          fill: $main-color !important;
        }
      }
    }
    &__body {
      counter-reset: commentCounter;
      display: flex;
      padding: 1.5em !important;
      flex-direction: column;
      flex-grow: 1;
      overflow: auto;
      background: #F6F7F8;
      margin-left: -1.5em;
      margin-right: -1.5em;
      // -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: thin;  /* Firefox */

      // &::-webkit-scrollbar {
      //   display: none;  /* Safari and Chrome */
      // }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba($grey, 0.3);
        border-radius: 1px;
        background-color: rgba($grey, 0.5);
      }

      &::-webkit-scrollbar {
        width: 2px;
        height: 0;
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 1px;
        -webkit-box-shadow: inset 0 0 6px rgba($grey, .3);
        background-color: $main-color;
      }
      @include media($mobile-menu-break) {
        max-height: 32vh;
      }

      @include media($tablet-max) {
        width: unset;
        padding-left: 2.5em;
        padding-right: 2.5em;
        background-color: #F6F7F8;
      }
    }
    &__footer {
      border-top: 1px solid #EBEBEB;
      padding: 1.5em;
      margin-left: -1.5em;
      margin-right: -1.5em;
      background: #fff;
      width: unset;

      @include media($tablet-max) {
        &.focus {
          position: absolute;
          bottom: 0;
          left: 0;
          margin: 0;
          width: 100%;
        }
      }

      &-field {
        position: relative;

        textarea {
          padding: 0.75rem 2rem 0.75rem 1rem;
          font-size: 14px;
          border: 1px solid #DDE0E5;
          resize: none;
        }

        > span {
          position: absolute;
          top: 8px;
          right: 8px;
          font-size: 10px;
          letter-spacing: 1px;
          color: #AAB3BE;
        }

        .flyout__field-input-icon {
          left: 5px;
        }

        .flyout__group-field {
          margin-top: 2px;
          width: unset;
        }

        .flyout__field-dropdown {
          background: $main-color;
          border: none;
          svg {
            fill: $white !important;
          }
        }

        .note-selected-member {
          display: flex;
          margin-top: -6px;
          margin-left: 5px;

          > span {
            display: flex;
            align-items: center;
            font-size: 12px;
            border: 1px solid $grey;
            border-radius: 2px;
            display: block;
            padding: 3px 2px 3px 5px;
            margin-right: 3px;
            color: $main-color;
            background: $white;

            svg {
              width: 20px;
              height: 20px;
              margin: -5px -2px -2px -3px;
              cursor: pointer;
              fill: $main-color;
            }
          }
        }

        .flyout__field-input {
          svg {
            fill: $main-color
          }

          input {
            padding: 0.5rem 2rem 0.5rem 1rem !important;
            font-size: 14px !important;
            border-color: $grey;
            background-color: $white !important;
            color: #333;
            padding-left: 3em !important;
          }
        }
      }
    }

    @include media($mobile-menu-break) {
      &.dropmenu-open .dropmenu-holder {
        top: 2.5rem;
      }
    }

    .dropmenu-holder {
      background: #EBEBEB;

      &:before {
        border-bottom-color: #EBEBEB;
      }


    }
  }

  &__report {
    margin-bottom: 40px;
    [collapsable] {
      .collapsable-trigger {
        top: 20px;
        right: 10px;
      }

      @include media($mobile-menu-break-max) {
        padding-right: 50px;
        .collapsable-trigger {
          width: 38px;
          height: 39px;
          background-color: lighten($grey, 10%);
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 2px solid darken( #EBEBEB, 5%);
        }
      }
    }

    thead {
      th {
        height: 38px;
      }
    }

    @include media($mobile-menu-break-max) {
      .template-table-holder {
        overflow: auto hidden;
      }
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;

    [class*='__actions'] {
      .btn:not(.btn-xs) {
        font-size: 11px;
        letter-spacing: 2px;
        height: 44px;
        padding: 0 20px;
        // border-color: $grey;
        // color: $grey;

        svg {
          height: 2.25em;
          width: 2.25em;
          margin-top: -0.75em;
          margin-right: -0.75em;
          margin-left: -0.25em;
        }
      }

      input {
        margin: 0;
        max-height: 40px;
        border: 1px solid $grey !important;
      }
    }
  }

  h2 {
    border: none;
    margin: 0;
    padding-bottom: 0;
    font-size: 24px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
      fill: $main-color;
      width: 38px;
      height: 38px;
      @include media($mobile-menu-break) {
        margin-right: 10px;
        width: 44px;
        height: 44px;
      }
    }
  }

  .dropmenu-trigger {
    &__btn {
      svg {
        // fill: $grey;
        width: 24px;
        height: 24px;
        margin-left: 5px;
      }

      // &:hover {
      //   svg {
      //     fill: currentColor;
      //   }
      // }
    }
  }

  .dataTables_info {
    font-size: 12px;
    letter-spacing: 0.5px;
    margin-left: 0;
  }

  .dataTables_paginate {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 2px;
    font-weight: bold;

    .paginate_button {
      &.disabled {
        display: none;
      }
    }
  }

  .dataTables_wrapper { padding-bottom: 0; }
}

.template-megaform {
  margin-bottom: 1em;

  &__header {
    margin-top: 1.325em;
    padding-top: 1em;
    border-top: 1.5px solid #DDE0E5;
  }

  &__toolbar {
    display: flex;
  }

  &__toolbar-item {
    display: flex;
    font-size: 14px;
    flex-direction: column;

    @include media($tablet) {
      ~ [class*='__toolbar-item'] {
        margin-left: 2.75em;
      }

      &--right {
        align-items: flex-end;
        flex-direction: column;
        margin-left: auto !important;
      }
    }


    [class*='__toolbar-item'] {
      margin-left: 20px;
    }

    #{$text-inputs-list} {
      padding: 0.25rem 3rem 0.25rem 0.5em;
      font-size: 14px;
      height: 32.15px;
    }

    .editable-wrap {
      .editable-controls {
        display: flex;
        > label {
          margin-right: 5px;
          align-self: center;
          font-weight: bold;
        }

        .editable-buttons {
          button {
            margin: 0 !important;
            padding: 0 !important;
            height: 100% !important;
            width: 70px !important;
            font-size: 10px !important;

            > .fa {
              display: none;
            }

            &[title="Submit"] {
              @include btn;
              top: -1px;
            }

            &[title="Cancel"] {
              @include btn-invert;
              letter-spacing: 2px;
              text-transform: uppercase;
            }

            // &:before {
            //   content: '';
            //   display: block;
            //   width: 100%;
            //   height: 100%;

            // }
          }


        }
      }
    }

    .editable {
      color: currentColor;
      border-bottom: dashed 1px currentColor;

      &.editable--disabled {
        border-bottom: none !important;
        pointer-events: none;
      }
    }
  }

  &__label {
    display: block;
    @include labelStyle;

    @include media($tablet) {
      font-size: 11px;
    }
  }

  &__value {
    font-size: .95rem;
    font-weight: 600;
    padding-top: 0.3em;
    line-height: 1.2;
    &--sm{
      font-size: .875rem;
    }
  }
}

[collapsable] {
  position: relative;
  .collapsable-trigger {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 1;
    svg {
      width: 32px;
      height: 32px;
    }

    &:not(.collapsed) {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .collapsable-container {
    margin: -10px;
    padding: 10px;
    background-color: #f1f1f1;
    border: 1px solid #00afeb;
    box-shadow: 0 0 0.875rem rgba(#00afeb, 0.5);
  }
  .collapsable-container + .collapsable-container{
    border-top:0;
    margin-top: -1px;
  }
}

[collapsable-target-highlight] {
  &.collapsable-active {
    position: relative;

    > * {
      position: relative;
      z-index: 3;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      height: calc(100% + 1px);
      width: 100%;
      top: 0;
      left: 0;
      border: 1px solid rgb(0, 175, 235);
      box-shadow: rgba(0, 175, 235, 0.2) 0px -0.5rem 1rem -0.25rem,
                  rgba(0, 175, 235, 0.15) 1rem 0rem 1rem -0.5rem,
                  rgba(0, 175, 235, 0.15) -1rem 0rem 1rem -0.5rem
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      z-index: 1;
      background-color: #f1f1f1;
      height: calc(100% + 20px);
width: calc(100% - 2px);
      top: 1px;
      left: 1px;
    }
  }
}

.collapsable-container {
  .reporting-inner-table {
    td:first-child {
      a {
        font-weight: normal;
        pointer-events: none;
      }
    }
  }
}

report-workgroup {
  .collapsable-container {
    .reporting-inner-table {
      td:first-child {
        a {
          pointer-events: unset;
          &:hover {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.editable-empty,
.editable-click {
  font-style: unset !important;
  color: inherit !important;
  border-bottom: dashed 1px currentColor !important;
}

.editable.editable--error {
  color: $red !important;
}

.templates-landing {
  padding: 0 10px;
  max-width: 90%;
  position: relative;
  text-align: center;
  margin: 0 auto 2.5rem;

  @include media($mobile-menu-break-max) {
    max-width: 100%;
  }


  .welcome-username {
    padding-bottom: 0;
  }

  &__feature-image {
    max-width: 567px;
    display: block;
    margin: auto;
  }

  &__footer {
    margin-top: 2.5em;
  }
}

.template-table-holder {
  // overflow: auto;
}

[template-id="1"] {
  > thead > tr {
    th {
      &:nth-child(1),
      &:nth-child(2) {
        width: 37.5%;
      }
    }
  }
}




.link-icon {
  color: $main-color;
}

.link-icon--muted {
  color: $grey;

  &:hover {
    color: darken($grey, 20);
  }
}


.template-docs--history {
  tbody { display: block; }
}


.template-docs--notes {
  padding: 0 0.5rem 0 .5rem;

}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0.75em 1em;
  &:hover {
    padding: 0.75em 1em;
  }
}

.previewDocumentPrompt {
  .modal-container {
    max-width: 520px;
  }
}


.template-chips {
  display: flex;
}
.template-chip {
  position: relative;
  background-color: $grey;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  margin-right: 3px;
  font-size: 14px;

  &--btn {
    background-color: $main-color;
  }

  svg {
    fill: currentColor;
  }

  &__badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 10px;
    width: 16px;
    height: 16px;
    background: $white;
    border-radius: 50%;
    color: $main-color;
    top: 1px;
    right: -3px;
  }
}

.popover {
  position: relative !important;
  cursor: pointer;
  &-content {
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    padding: 0.5rem 0.75rem 0.325rem;
    background-color: #ebebeb;
    border: 1px solid #ddd;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.125);
    z-index: 1;
    color: $black;
    font-size: 12px;
    transform: translateX(-50%) scale(0.2);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    opacity: 0;
    visibility: hidden;

    &:before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background: #ebebeb;
      border-top: 1px solid #ddd;
      border-left: 1px solid #ddd;
      position: absolute;
      top: -6px;
      left: 50%;
      margin-left: -5px;
      transform: rotate(45deg);
      box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.0325);
    }
  }

  &:hover {
    .popover-content {
      top: calc(100% + 5px);
      transform: translateX(-50%) scale(1);
      opacity: 1;
      visibility: visible;
    }
  }

  &--right {

    > [class*='-content'] {
      right: calc(-50% + 15px);
      left: unset;
      transform: scale(0);

      &:before {
        left: unset;
        margin: 0;
        right: 10px;
      }
    }
    &:hover {
      > [class*='-content'] {
        transform: scale(1);
      }

    }
  }
}

.template-sharing-list {
  max-height: 320px;
  overflow: auto;
  display: flex;
  list-style-type: none;
  margin: 0 0 40px;
  flex-wrap: wrap;

  > li {
    padding: 0 20px;
    flex: 1 1 50%;
    max-width: 50%;
    margin: 20px 0 0 0;
  }

  .account-details {
    font-size: 13px;
  }
}

.template-sharing-account {
  display: flex;
  align-items: flex-start;

  > .image {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-weight: bold;
    background-color: $grey;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin-right: 20px;
    flex-shrink: 0;
  }

  .share-template-btn {
    margin-left: auto;
    cursor: pointer;
    svg {
      width: 1.75rem;
      height: 1.75rem;
      fill: $main-color;
    }
  }
}

.template-megaform__value {
  .popover-content {
    .share-template-to {
      display: flex;
      justify-content: space-between;
      min-width: 160px;


    }
  }
}


[collapsable] {
  .dataTables_wrapper {
    padding: 0.5rem 0 0;

    > table {
      margin: 0;
    }
  }

  h3 {
    border: none;
    margin: 0;
    font-size: 16px;
    padding-top: 10px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
      fill: $main-color;
      @include media($mobile-menu-break) {
        margin-right: 10px;
        width: 44px;
        height: 44px;
      }
    }
  }
}


[date-range-picker="t1"] {
  max-height: 44px;
  margin: .3rem 0 .3rem;
  padding: 0.75rem 1rem!important;
  font-family: $bold-font !important;
  font-size: 12px !important;
  letter-spacing: 1px;
  border-width: 1px !important;
  width: 215px !important;
}

.moment-picker{
  z-index: 999999 !important;
}

.daterangepicker {
  &[style*='display: block;'] {
    display: flex !important;
    max-width: 567px;
    z-index: 99999;
  }
  flex-wrap: wrap;
  border: 1px solid #ddd;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.125);

  &:not([style]) {
    display: none !important;
  }

  > .calendar {
    display: block !important;
    float: none;
    flex: 1 1 50%;
    padding: 10px;
    margin: 0;
    max-width: 50%;

    .calendar-table {
      padding: 0 !important;
    }
  }

  > .ranges {
    align-items: center;
    display: flex;
    flex-grow: 1;
    padding: 10px;

    .range_inputs {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;

      .btn {
        margin: 5px;
      }
    }

    > ul {
      margin: 0 auto 0 0;
      display: flex;
      width: auto;


      li {
        padding: 5px 1em;
        border-radius: 0;
        margin: 0;
        margin-right: 5px;
        &:last-child { display: none; }
      }
    }
  }

  table {
    table-layout: auto;

    select {
      @include input-base;
      padding: 0.25rem !important;
      display: inline-block;
      border-width: 1px;
      font-family: $light-font;
      background-position: right center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('#{$svg-path}Icons/Angle Down Sm.svg');
      width: 60px !important;
    }

    td {
      padding: 10px 0;
      font-family: $light-font;
      font-size: 12px;
    }

    th {
      padding: 10px 0;
    }
  }
  .glyphicon {
    position: relative;
    display: block;
    width: 18px;
    height: 18px;
    margin: auto;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &.glyphicon-chevron-left:before {
      background-image: url('#{$svg-path}Icons/Angle Back.svg');
    }
    &.glyphicon-chevron-right:before {
      background-image: url('#{$svg-path}Icons/Angle Forward.svg');
    }
  }

  .daterangepicker_input {
    display: none;
  }
}

.dot {
  position: absolute;
  display: block;
  background: red;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  z-index: 99999;
}


@include media($desktop-max) {
  .template-megaform {
    &__value {
      font-size: 1.05
    }
  }
}

@include media($lg-desktop) {
  .template-megaform {
    &__toolbar {
      &-item {
        &--right {
          padding-top: 19px;
          flex-direction: row;
          align-items: flex-start;
          white-space: nowrap;
          padding-left: 2.5em;
        }
      }
    }
  }

}

[collapsable-target] {
  .collapsable-target--close {
    display: none;
  }
  &.collapsable-open {
    .collapsable-target--open {
      display: none;
    }

    .collapsable-target--close {
      display: unset;
    }
  }
}

@include media($tablet) {
  .templates-datatable {
    table-layout: fixed !important;
    thead {
      tr {
        th:nth-child(1) {
          width: 18% !important;
        }
      }
    }
  }
}

.dropmenu-trigger__btn.comments-count {
  display: flex;
  align-items: center;
  > span {
    text-indent: 8px;
    min-width: 40px;
    display: block;
    font-size: 1.075em;
  }

  > svg {
    display: block;
    position: relative;
    margin: 0 -10px;
  }
}

// .template-comments.dropmenu-trigger {
//   svg {
//     fill: $main-color
//   }

//   &:hover {
//     svg {
//       fill: $black;
//     }
//   }
// }

.template-table {
  &.f5-performance-reviewer {
    .template-table__field {
      min-height: unset;

      .st-dropdown {
        display: flex;
        height: 100%;
        .st-dropdown-trigger {
          height: 100%;
        }
      }
    }

    .template-table__legend {
      .template-table__field {
        min-height: 200px;
      }

      &[colspan="3"] {
        // background: #000;
        border: 2px solid #e8e9ea
      }
    }
  }
  &.game-plan {
    table-layout: fixed;
    overflow: hidden;
    &--comissioner {
      // table-layout: auto;

      // thead {
      //   th:first-child {
      //     width: 40%;
      //   }
      // }

      .cell_heading {
        text-align: center;
      }

      > tbody {
        > tr {
          > td:nth-child(1) {
            [ng-bind-html] {
              position: absolute;
              left: 0;
              top: 0;
              width: 200%;
              z-index: 1;
            }
          }
          > td:nth-child(1),
          > td:nth-child(2) {
            > .template-table__cell {
              padding-top: 31px;
            }
          }
        }
      }
    }
  }

  .flex-cell--headings {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      padding: 0;
      margin: 0;
      flex: 1;
      text-align: center;
    }
  }
}

%cellArrow {
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-left: 22px solid $template-border-color;
    border-bottom: 20px solid transparent;
    top: calc(50% - 20px);
    left: -1px;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -2px;
    width: 2px;
    height: 100%;
    background: $template-border-color;
    z-index: 1;
  }

}

table[template-id="4"],
table[template-id="5"] {
  > tbody {
    td {
      div[name*="rating_"] {
        background: $template-border-color !important;
      }

      &:last-child {
        @extend %cellArrow;
        template-field > div{
          padding-left: 20px;
        }
      }
    }
  }
}

table.f5-performance-reviewer {
  table-layout: auto !important;
  > tbody {
    tr:first-child {
      > td {
        &:not(:first-child) {
          width: 50%;
        }
      }
    }


    .template-table__cell {
      [ng-bind-html]{
        padding: 5px 1em;

        &::before {
          font-size: 13px;
          white-space: nowrap;
        }
      }
      [name*="rating_"] {
        padding: 5px 10px;

        &::before {
          font-size: 13px;
          white-space: nowrap;
        }
      }

      &-legend {
        position: relative;
        padding: 5px 0;
        background: #dedede;
        color: $black;
        font-family: Arial, serif !important;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;

        [class*='__cell'] {
          margin: -17px -12px -17px;
        }
      }
    }
    [data-row-length="4"] {
      td {
        &:first-child,
        &:nth-child(3) {
          background: $template-border-color !important;
        }

        &:nth-child(3) {
          width: 160px;
        }
      }
    }
    [data-row-length="6"] {
      td {
        &:first-child {
          width: 160px;
        }

        &:first-child,
        &:nth-child(3),
        &:nth-child(4),
        &:last-child {
          background: $template-border-color !important;
        }

        &:nth-child(2),
        &:nth-child(5) {
          width: 168px;
        }
      }
    }

    .vertical-cell {
      transform: translate(-1em, -0.35em);
    }

    td {
      font-size: 13px;
    }

  }
}

table.reduced-rating-size {
  + .progress-block {
    li:not(.active) {
      .progress-block__label {
        font-size: 8px;
      }
    }
  }
}

[data="summary"] {
  &.open-dropdown {
    z-index: 99999 !important;
  }
}

[dash-chart] {
  @include media($mobile-menu-break) {
    height: 500px;
  }
}


.template-diagram {
  opacity:0;
  visibility: hidden;
  transform:scale(.9,.9);
  transition:all .35s linear;
  position: absolute;
  left: 0;
  width: 100%;
  height:100%;


  &.active{
    transform:scale(1,1);
    visibility: visible;
    opacity:1;
  }

  path.active{ //hide paddlock
    opacity:0;
  }

  .st0{
    fill: transparent;
  }

  > svg {
    width:100%;
    height:100%;
    max-width: 94vw;
    max-height: 60vh;
    object-fit: contain;

    @include media($mobile-menu-break) {
      max-height: 70vh;
      max-width: 70vw;
    }
  }

  .st0 { fill: #1BBCEE; }
  .st1 { fill: $white; }

  .diagram-btn {
    transform-origin: center;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    filter: url(#diagram_shadow_light);

    &#diagram_step1 { transform: translate(202.723734px, -0.000217px); }
    &#diagram_step2 { transform: translate(197.055656px, 202.818791px); }
    &#diagram_step3 { transform: translate(0.265478px, 197.056912px); }
    &#diagram_step4 { transform: translate(0.000000px, 0.273936px); }
    &#diagram_center { transform: translate(124.946356px, 124.810482px); }


      &:hover {
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transform: scale(1.125);
        filter: url('#diagram_shadow');
        // filter: drop-shadow(0px 0px 10px rgba(0,0,0,.5));
        z-index: 10;
      }

      &#diagram_step1:hover {
        transform: translate(215px, 22px) scale(1.125);
      }

      &#diagram_step2:hover {
        transform: translate(206px, 218px) scale(1.125);
      }

      &#diagram_step3:hover {
        transform: translate(18px, 210px) scale(1.125);
      }

      &#diagram_step4:hover {
        transform: translate(20px, 20px) scale(1.125);
      }
      &#diagram_center:hover { transform: translate(141.7px, 145px) scale(1.125); }

    &:not(.disabled) {
      cursor: pointer;
      .inactive { display: none; }
    }

    &.disabled {
      .active { display: none; }
      .st0 {
        fill: #666 !important;
      }
      .st1 {
        fill: #444 !important;
      }
      .icon > path {
        fill: #333 !important;
      }
    }

    > g {
      pointer-events: none;
    }
  }
}

%tagHoverState {
  [class*='__btn-inner'] {
    border: 1px solid $main-color !important;
    border-right: none !important;
    background: $main-color;

    &:before,
    &:after {
      background: $main-color;
    }
  }
}

.opt-phase-nav {
  margin: 1em 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  @include media($mobile-menu-break) {
    position: absolute;
    top: 5%;
    left: 0;
    margin: 0;
    padding: 2.5% 0;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;

    &--right {
      left: unset;
      right: 0;
    }
  }

  > li {
    padding: 0;
    margin: 0 0 1.25em;
    @include media($mobile-menu-break-max) {
      margin-bottom: 0;
      &:not(:first-child) {
        [class*='__btn-inner'] {
          padding-left: 16px;
        }
      }

      &:last-child {
        [class*='__btn-inner'] {
          border-right: 1px solid $white;
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 10px;
    font-family: $reg-font;
    > span {
      width: 100%;
    }
    @include media($mobile-menu-break) {
      font-size: 13px;
    }
  }

  &__btn-outer {
    margin-bottom: 5px;
  }

  &__btn-inner {
    position: relative;
    min-height: 40px;
    background: transparent;
    border: 1px solid $white;
    border-right: none;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    padding: 2px 6px;
    justify-content: center;
    line-height: 1.125;
    transition: 0.125s all linear;

    @include media($mobile-menu-break) {
      min-width: 86px;
      min-height: 52px;
    }

    > .name {
      text-transform: uppercase;
      font-weight: bold;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: -1px;
      left: calc(100% - 1px);
      height: calc(100% + 2px);
      padding-right: 18%;
      background: $white;
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
      transition: 0.125s all linear;
    }

    &:after {
      background: $dark-dark-blue;
      left: calc(100% - 2px);
      border-radius: 2px;
      z-index: 1;
    }

    > .channel-name {
      fill: currentColor;
      height: 26px;
      width: 100px;
    }
  }

  a {
    display: block;
    color: $white;

    &.disabled {
      opacity: 0.25;
      pointer-events: none;
    }
    &:hover {
      @extend %tagHoverState;
    }
  }

  &--right {
    [class*='__btn-inner'] {
      border-right: 1px solid $white;
      &:before,
      &:after {
        display: none;
      }
    }
  }
}

.framework-deployment-btn {
  margin: 1em auto 0;
  display: block;
  color: $white;
  max-width: 140px;

  @include media($mobile-menu-break) {
    margin: unset;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .opt-phase-nav__btn-inner {
    border: 1px solid $white !important;
    text-align: center;
    padding: 8px 25px;

    &:before,
    &:after {
      display: none;
    }
  }

  &:hover {
    @extend %tagHoverState;
  }
}

.templates-landing-block {
  position: relative;
  height:100%;
  @include media($mobile-menu-break-max) {
    padding: 0;
  }
}

table[class*='12month-progress-reviewer'] {
  > tbody {
    > tr {
      &:not([data-row-length="4"]) {
        > td {
          width: 45%;
        }
      }
      &[data-row-length="4"] {
        > td {
          width: 30%;
        }
      }
      > td {
        &:first-child {
          width: 0;
        }
      }
    }
  }
}

body.articles-page.myTemplates {
  .post-delivery-article-navitem > span:first-child {
    display: block;
    font-weight: bold;
  }
}


.myTemplates .modal-body {
  border: none !important;
}

@include media($tablet) {
  .template-docs__footer {
    .team-controls {
      > .delete {
        display: none;
      }
    }
  }
}
@include media($tablet-max) {
  .myTemplates article .article-body {
    padding-left: 0;
    padding-right: 0;
  }

  [data="summary"] {
    .dataTables_wrapper {
      .dataTables_info {
        display: none;
      }
      > table {
        margin-top: 0;
      }
    }
  }

  .template-docs {
    &__heading {
      flex-direction: column-reverse;
      > * {
        width: 100%;
      }

      .template-docs__actions {
        justify-content: flex-end;

        .dropmenu-holder {
          top: 100%;
          left: unset;
          right: 0;
        }
      }
    }
  }
}

.note__suggestion-list {
  border: 2px solid #bfbfbf;
  border-top: none;

  > li {
    border: none !important;

    span {
      display: block;
      padding: 0.5em;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background: $main-color;
        color: $white;
      }
    }
  }
}

.note-visible-label {
  margin-top: 12px;
  display: flex;
  .template-megaform__label {
    margin-bottom: 0;
  }
}

.dataTables_wrapper {
  thead {

    .tooltip--open {
      .tooltip__content {
        z-index: 8;
      }
    }
  }
}

.ng-placeholder {
  color: #aaa;
  user-select: none;
  pointer-events: none;
}

.template-docs,
.template-block,
.admin-block {
  .dropmenu-holder {
    background: #ebebeb;
    border: 1px solid #ddd;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.125);

    @include media($mobile-menu-break) {
      &:before {
        content: '';
        border-color: transparent;
        display: block;
        width: 20px;
        height: 20px;
        background: #ebebeb;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        transform: rotate(45deg);
        box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.0325);
        top: -0.5rem;
      }

      &--top {

        &:before {
          top: unset;
          bottom: -0.5rem;
          transform-origin: center;
          transform: rotate(220deg);
        }
      }
    }

    &--first-over {
      &:before {
        background: $main-color;
      }
    }

    .dataTables_wrapper {
      padding: 0;

      table {
        td {
          background: $white;
        }
        th, td {
          font-size: 12px;

          .btn-xs {
            font-size: 10px;
            padding: 7px 10px 5px;
          }
        }
      }
    }
  }
}


.reporting-head .template-docs__actions .dropmenu-holder ul li a.link--active {pointer-events: unset;}

.template-docs__footer {
  @include media($tablet-max) {
    .team-controls {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 10px;

      > li {
        button, .btn { margin: 0; padding: 1.4em 1em; font-size: 10px; min-width: unset; }
        padding: 0;

        &.span-2 { grid-column: span 2 / span 2; }
        &.ng-hide + .span-2 { grid-column: span 1 / span 1; }
      }

    }
  }
}

:root {
  --scrolled-transition: all 0.5s cubic-bezier(.52,.04,.07,1.11);
  --bg-template: #F6F7F8;
}

@include media($tablet) {
  .mobile__template-save,
  .mobile__template-form {
    display: none !important;
  }
}
.template-page {
  @include media($tablet-max) {
    .main-header [ng-if*="currentArticle"] {
      display: block;
      max-width: 45%;
      white-space: nowrap;

      > span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    [class*="template-docs__header"],
    .alert.alert-success,
    .alert.alert-error {
      display: none;
    }

    .articles-main {
      background: var(--bg-template);
      overflow: unset;
    }

    .template-docs__progress {
      transform: translateY(0px);
      border-top: none;

      [class*="__steps"] { margin-left: -25px; margin-right: -10px; position: relative; z-index: 1;}
      [class*="title"] { font-size: 15px; letter-spacing: 0.25px; font-weight: 600; margin-top: 0; }
      [class*="link"] { font-size: 11px; letter-spacing: 0.25px; font-weight: 600; opacity: 1; }
      [class*="__item"]:after { width: 24px; height: 24px; background-size: 32px; background-position: -5px -5px; }
      span[class*="__item"]:before { height: 6px; margin-bottom: 1em; }
    }

    .template-megaform__toolbar {
      position: relative;
      background: rgba(#fff, 0.9);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;

      [class*="megaform__label"] { white-space: unset; }

      .template-chips {
        > .template-chip {
          margin-right: -10px;
          width: 36px;
          height: 36px;
        }
      }
    }

    input[name*="period"] {
      margin: 0;
      padding: 7px 0 0;
      border: none;
      background: unset;
      height: unset;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .template-chips .link {
    &:after { display: none; }
    .btn__icon { transform: translateY(5px); }
  }
}

.mobile__template-header {
  background-color: rgba(#fff, 0.9);
  margin: -30px -15px 0;
  padding: 30px 15px 20px;
  border-bottom: 1px solid #DDE0E5;

  @include media($mobile-menu-break) {
    position: relative;
    display: flex !important;
    justify-content: space-between;
    background: unset;
    border-bottom: none;
    padding: 0 15px;
    margin-top: -35px;

    &.indented { padding-right: 5em; }

    [ng-click*="deleteTemplateModal"] {
      position: absolute;
      right: 16px;
    }

    .subtitle {
      align-items: baseline;
      margin-top: 12px;
      [class*="__value"] { font-size: 0.9em; }
    }
  }

  .title {
    display: flex;
    align-items: flex-start;
    strong  {
      font-size: 26px;
      line-height: 36px;
      letter-spacing: 0.25px;
      color: #38444B;
    }
  }

  .subtitle {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }

  [class*="label"],
  [class*="value"] {
    margin: 0;
    padding: 0;
    display: inline-block;
    margin-right: 12px;
  }
}

.mobile__template-toolbar-trigger {
  cursor: pointer;
  position: relative;
  z-index: 1;
  grid-column: span 2 / span 2;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  @include media($mobile-menu-break) {
    display: flex !important;
    align-items: center;
    grid-column: span 4 / span 4;
    margin-bottom: -3.25em;
  }


  &:before {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    margin: 0 auto;
    border-radius: 4px;
    background: rgba(#DDE0E5, 0.75);
  }
}


@include media($tablet-max) {
  .template-docs__notes.dropmenu-open .dropmenu-holder {
    transform: translateY(0px);
  }
  .template-docs__notes .dropmenu-holder {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transform: translateY(100vh);
  }
}

.template-docs--notes {
  counter-reset: commentCount;
}

.template-docs__note {
  counter-increment: commentCount;
  display: flex;
  padding: 1em 0;

  &:before {
    content: counter(commentCount)'.';
    display: block;
    min-width: 35px;
  }

  &__buttons {
    padding-left: 24px;
    width: 50px;
    .btn__icon {
      fill: #AAB3BE;
      width: 26px;
      height: 26px;
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__comment {
    position: relative;
    margin-bottom: 1.25em;
    &:before {
      content: '';
      display: block;
      position: absolute;
      inset: -10px -1em;
      border: 1px solid #DDE0E5;
    }
  }

  &__sender {
    display: flex;
    justify-content: space-between;
    color: #38444B;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 16.5px;

    > div {
      display: flex;
      flex-direction: column;
    }

    strong {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 1.5px;
      color: #AAB3BE;
      letter-spacing: 1.5px;
    }

    .toggle {
      margin-top: -1.25rem;
      margin-right: -1.75em;
      display: block;
      position: relative;
      font-size: 10px;
      color: #AAB3BE;
      display: flex;
      align-items: center;
      &:after {
        content: '';
        margin-top: -1px;
        width: 24px;
        height: 24px;
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        filter: invert(79%) sepia(3%) saturate(1089%) hue-rotate(173deg) brightness(91%) contrast(90%);
        background-image: url('#{$svg-path}Icons/Angle Down Sm.svg');
      }

      &.active {
        &:after {
          background-image: url('#{$svg-path}Icons/Angle Up Sm.svg');
        }
      }
    }
  }

  &__details {
    color: #38444B;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 16.5px;

    > div {
      margin-top: 6px;
      display: flex;
      flex-direction: column;

      &.visible-to {
        flex-direction: row;
        flex-wrap: wrap;

        strong {
          display: block;
          width: 100%;
        }

        span + span { padding-left: 3px; }
      }
    }

    strong {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 1.5px;
      color: #AAB3BE;
      letter-spacing: 1.5px;
    }
  }


}

.template-docs__notes {
  &__footer-buttons {
    padding-left: 1em;
    display: flex;
    align-items: start;

    .btn {
      margin-top: 10px !important;
      min-width: 100px;
      min-height: 38px;
      transform: translate(-20px, 20px);
    }
  }
  &__footer-visibility {
    margin-right: auto;
    ul {
      display: flex;
      flex-direction: column;

      li {
        margin-top: 10px;
        label {
          margin: 0;
          padding-top: 0;
          padding-bottom: 0;
          color: #38444B;
          font-size: 13px;
          font-weight: 600;
          letter-spacing: 0.25px;
          line-height: 17px;
        }
      }

      .template-docs__note__details { margin-left: 25px; }
    }
  }
}

.template-docs--notes-empty {
  min-height: 200px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.5px;
  color: #AAB3BE;
  letter-spacing: 1.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -2.125em;
  margin-right: -2.125em;
}

.template-block--layout-component {

  @include media($tablet-max) {
  .progress-block { display: none; }
  .template-megaform {
    table {
        display: none;
      }
    }
  }
}


.form__label {
  @include labelStyle();
}

.mobile__template-form {
  section + section { margin-top: 60px; }
  .heading {
    display: flex;
    padding-bottom: 3px;
    justify-content: space-between;
    color: #38444B;
    border-bottom: 1px solid #AAB3BE;
    h3 {
      padding: 0;
      margin: 0;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.25px;
      line-height: 30px;
    }
    > span {
      cursor: pointer;
      svg {
        width: 32px;
        height: 32px;
        fill: #38444B;
      }
      &.active svg { fill: $main-color; }
    }
  }


  .diagram {
    padding: 20px 25%;
    display: block;
    margin: 0 auto;
    border-bottom: 1px solid #DDE0E5;
  }

  .question p:last-child { margin-bottom: 0; }
  .question h4 { padding-top: 0; }

  .questionnaire {
    padding-top: 20px;
    + .questionnaire { margin-top: 20px; border-top: 1px solid #DDE0E5; }
  }

  .questionnaire-group {
    display: flex;
    .question {
      padding-right: 20px;
      flex: 1;
      h4 {
        margin: 0;
        padding: 0;
        color: #38444B;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.25px;
        line-height: 22.5px;
      }
      p {
        margin: 0;
        padding: 0;
        color: #38444B;
        font-size: 13px;
        letter-spacing: 0.25px;
        line-height: 19.5px;
      }
    }
    .rating {
      position: relative;
      margin: 0;
      display: flex;
      flex-direction: column;
      max-width: 50px;
      text-align: center;

      .form__label { margin-bottom: 5px; }
      textarea {
        margin: 0;
        padding: 10px 5px;
        text-align: center;
        background: #FFFFFF;
        border: unset;
        resize: none;
        color: #262626;
        font-size: 13px;
        letter-spacing: 0.25px;
        line-height: 19.5px;
        height: 42px;

        &:disabled {
          background: unset;
        }
      }

      template-field.ng-invalid {
        border: 1px solid #ED1E23;
        box-shadow: #ED1E23 0px 0px 0.75rem;
      }

      .validate {
        display: block;
        width: 130px;
        font-size: 11px;
        left: unset;
        right: 0;
        padding: 10px;
        &:before {
          left: unset;
          right: 12px;
        }
      }
    }
  }

  .questionnaire-table {
    margin: 10px 0 0;
    dt {
      padding: 15px;
      background: #EEF0F2;
      color: #38444B;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.25px;
      line-height: 19.5px;
    }
    dd {
      position: relative;
      .mce-content-body {
        margin: 0;
        padding: 15px;
        background: #FFFFFF;
        border: unset;
        resize: none;
        color: #262626;
        font-size: 13px;
        letter-spacing: 0.25px;
        line-height: 19.5px;
        min-height: 110px;
        &:not([contenteditable]) { background: unset; }
      }
      .validate { display: block; font-size: 11px; padding: 10px; }
      template-field { position: relative; display: block; }
      .mce-edit-focus ~ .input-counter { display: block; }
      template-field.ng-invalid .mce-content-body {
        border: 1px solid #ED1E23;
        box-shadow: #ED1E23 0px 0px 0.75rem;
      }

      template-field.ng-invalid-max-lines {
        .mce-content-body,
        textarea {
          border: 1px solid $warning-color;
          box-shadow: $warning-color 0px 0px 0.75rem;
        }

        + p.validate {
          background-color: $warning-color;
          color: $dark-blue;
          &:before {
            border-bottom-color: $warning-color;
          }
        }
      }

    }
  }
}

modal-delegate-authority .modal-body {
  overflow: unset !important;
}

body.template-page {
  @include media($tablet-max) {
    templates {
      display: block;
      overflow-x: hidden;
    }

    [ng-show="showLastTemplateModal"] {
      .form-buttons button > span { display: none; }
    }

    .modal-container .form-note { font-size: 12px; line-height: 1.6; }

    modal-delegate-authority {
      display: block;

      .modal-body {
        overflow: unset;
        .flexbox {
          flex-direction: column;

          > div:last-child {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(2, 1fr);
            margin: 10px 0 0 !important;

            button { height: 36px; }
          }
        }
      }
    }
  }
}

[name="templateForm"] .view-level-widget__button {
  background-color: $white !important;
  svg { fill: $main-color !important; margin-top: 0 !important; }
}

.note-suggestions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F6F7F8;
  z-index: 1;
  transition: all 0.15s ease-in-out;
  opacity: 0;
  visibility: hidden;

  &.active { opacity: 1; visibility: visible;   }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ebebeb;
    font-size: 17px;
    font-weight: 600;
    padding: 12px 16px;
    .modal-close{
      position: relative;
      margin:-5px;
    }

    .svg-icon {
      width: 38px;
      height: 38px;
    }

    svg {
      cursor: pointer;
      fill: $main-color !important;
    }
  }

  .body {
    padding:15px 20px 20px 20px;
    flex-grow: 1;
    overflow: auto;

    @include media($tablet) {
      max-height: 500px; max-width: 500px;
      padding: 20px 25px 25px 25px;
    }

    .owner {
      color: #38444B;
      font-size: 15px;

      strong { font-weight: 600; }
    }
  }
  .footer{
    border-top:1px solid #ccc;
    padding:20px 25px 25px 25px;
  }


}

.note-suggestions__members {
  label.checkbox:before,
  label.checkbox:after { width: 26px; height: 26px; top: -5px; filter: unset; }

  > li {
    display: flex !important;
    justify-content: space-between;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.template-docs__notes__footer-field textarea {
  padding-top: 10px;
  padding-left: 12px;
  font-size: 15px;
  background: unset !important;
  border-width: 2px;

  &::placeholder { color: #AAB3BE; }
}

.template-megaform__toolbar {
  @include media($mobile-menu-break) {
    display: grid;
    gap: 1em;
    grid-template-columns: 30% 1fr 1fr 1fr;
    [class*='__toolbar-item'] { margin: 0; }
  }

  padding-right: 35px;

  .select-holder {
    margin-top: 3px;
    background-color: unset !important;
    border: unset !important;
    max-width: 190px;
    @include media($tablet) { margin-top: 4px; }


    select {
      padding: 0;
      font-weight: 600;
      z-index: unset;
      font-size: .95rem;
      padding-right:35px;
      @include media($tablet-max) {
          font-size: 15px;
      }
    }

    &:before {
      width: 28px;
      height: 28px;
      margin-top: -14px;
    }
  }

  .further-textarea{
    height:42px;
    border:1px solid #dAe3eE;
    background-color: #fff;
    &::placeholder { color: #AAB3BE; }
  }


  .period-data.input {
    display: flex;
    width:100%;
    flex-direction: column;

    input{
      max-width: 150px;
      background: unset !important;
      border: unset !important;
      padding: 0 !important;
      font-weight: 600;
      margin-top: 6px;
      margin-bottom: 0;
      font-size: .9rem;
      @include media($tablet) {  margin-top: 2px; }
    }


    button {
      background: none;
      border: none;
      cursor: pointer;

      svg {
        width: 22px;
        height: 22px;

        @include media($mobile-menu-break) {
            width: 26px;
            height: 26px;
        }
        fill: #AAB3BE;
      }
    }

    .period-info{
      margin:1px 0 -1px;
      line-height: 1;
      font-size:12px;
    }
    //position: relative;
    //padding-right: 30px;
    /*&:after {
      pointer-events: none;
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      right: 1px;
      width: 28px;
      height: 28px;
      background: url('#{$svg-path}Icons/Apply.svg') no-repeat center -1px !important;
      background-size: contain !important;
      filter: invert(85%) sepia(0%) saturate(255%) hue-rotate(151deg) brightness(86%) contrast(85%);

      @include media($tablet) { top: 4px; }
    }*/
  }
}

@mixin action-text-icon-indicator {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  color: #AAB3BE;
  font-weight: 700;
  font-size: 26px;
  margin-top: -5px;
  font-style: normal;
}

.template-megaform__toolbar-item {
  .delegate {
    display: flex;
    cursor: pointer;
    &[ng-click*="show"] em {
      font-size: 18px;
      transform: translateY(2.5px);
      @include media($tablet) { transform: translateY(4px); }
    }
    em { margin-right: 2px; @include action-text-icon-indicator(); @include media($tablet) { margin-left: 0.75em; } }

    .template-chips { display: none; }
    @include media($mobile-menu-break) {
      &:not([ng-click*="show"]) > span { display: none; }
      .template-chips {
        display: flex;
        & ~ em { margin-top: -1px; }
      }
    }
  }
}

.mobile__template-head-clone {
  background: rgba(#fff, 0.9);
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: var(--scrolled-transition);
  transition-duration: 0.25s;
  transform: translateY(0);

  @include media($mobile-menu-break) {
    top: 0;
    left: $sidebar-width;
    width: calc(100% - #{$sidebar-width});
    // display: block !important;
    // opacity: 1;
    // visibility: visible;

    .template-head-clone {
      max-width: $container-maxwidth;
      margin: 0 auto;
      padding: 0 50px;
    }
  }

  .template-megaform-holder { border-bottom: 1px solid #DDE0E5; }

  .mobile__template-header { margin-top: 0; }

  .mobile__template-header,
  .template-docs__progress,
  .template-megaform__toolbar { margin-left: 0; margin-right: 0; background: unset; }
  .mobile__template-toolbar-trigger { margin-top: 15px; margin-bottom: 0; }

  [name="templateForm"].scrolled & {
    opacity: 1;
    visibility: visible;
    z-index: 2;
    @include media($mobile-menu-break) {
      opacity: 0;
      z-index: -1;
      visibility: hidden;
    }
  }

  &.collapsed {
    [class*='__toolbar-item'],
    .mobile__template-header { opacity: 0; visibility: hidden; }
    .template-docs__progress {
      [class*="__title"],
      [class*="__steps"] a {
        opacity: 0;
        margin-top: -45px;
        visibility: hidden;
      }
    }
    [class*='template-toolbar-trigger'] {
      margin-top: -0.5em;
      padding-top: 2.75em;
      padding-bottom: 10px;
    }
  }


  &.animating {
    .template-megaform__header,
    .mobile__template-header,
    .template-megaform-holder,
    [class*='__toolbar-item'],
    [class*='toolbar-trigger'],
    .template-docs__progress [class*="__title"],
    .template-docs__progress [class*="__title"] a { transition: var(--scrolled-transition); }
  }
}


body.template-list templates [ui-view],
body.archive-page templates {
  display: block;
  table-list[data="summary"] {
    display: block;

    .dataTables_wrapper {
      > .dataTable {
        display: block;
        width: 100% !important;
        thead { display: none; }
        tbody { display: block; }

        tr {
          display: flex;
          flex-wrap: wrap;
          background: #fff !important;
          margin-bottom: 15px;
          &:after {
            content: '';
            display: block;
            order: 4;
            width: calc(100% - 20px);
            height: 1px;
            transform: translateX(10px);
            background: #EEF0F2;
          }

          border-left: 3px solid #F6F7F8;
          &.draft { border-color: #AAB3BE; }
          &.view,&.proposed { border-color: $main-color ; }
          &.review { border-color: #38444B; }
          &.approved,&.archived { border-color: #7ED321; }

          @include media($mobile-menu-break) { padding: 0 10px; }
        }

        &.empty tr {
          padding: 0;
          &::after { display: none; }
        }

        td[ng-hide*="includes('recipient')"],
        td[ng-hide*="includes('status')"],
        td[ng-hide*="includes('comments')"] .comments-count,
        td[ng-hide*="includes('actions')"],
        td[ng-hide*="includes('date')"],
        td[ng-hide*="includes('modified')"],
        td[ng-hide*="includes('owner')"],
        td[ng-hide*="includes('manager')"] {
          display: block !important;
          box-sizing: border-box;
          border: unset;
          &:before {
            order: 0;
            width: 100%;
            display: block;
            @include labelStyle();
          }
        }

        td, th {
          &[ng-hide*="includes('template')"] { display: none; }

          &[ng-hide*="includes('owner')"],
          &[ng-hide*="includes('manager')"] {
            @include media($mobile-menu-break-max) {
              display: none !important;
            }
          }
        }

        td[ng-hide*="includes('recipient')"]:before { content: 'Owner / Recipient'; }
        td[ng-hide*="includes('status')"]:before { content: 'Status'; }

        td[ng-hide*="includes('date')"]:before { content: 'Period'; }
        td[ng-hide*="includes('modified')"]:before { content: 'Last Modified'; }

        td[ng-hide*="includes('owner')"]:before { content: 'Started by:'; }
        td[ng-hide*="includes('manager')"]:before { content: 'Manager:'; }

        td[ng-hide*="includes('comments')"] { box-sizing: border-box; border: unset; }
        td[ng-hide*="includes('comments')"] .comments-count { display: flex !important; }
        td[ng-hide*="includes('comments')"] .comments-count:before { content: 'Comments:'; width: unset !important; }
        // td[ng-hide*="includes('actions')"]:before { content: 'Actions'; }

        td[ng-hide*="includes('recipient')"] { order: 1; }
        td[ng-hide*="includes('status')"] { order: 2; @include media($mobile-menu-break) { order: 3; } }
        td[ng-hide*="includes('date')"] { order: 3; @include media($mobile-menu-break) { order: 2; } }
        td[ng-hide*="includes('modified')"] { order: 4; z-index: 1; }
        td[ng-hide*="includes('comments')"] { order: 5; @include media($mobile-menu-break) { order: 7; } }
        td[ng-hide*="includes('actions')"] { order: 6; @include media($mobile-menu-break) { order: 8; } }
        td[ng-hide*="includes('owner')"] { @include media($mobile-menu-break) { order: 5; } }
        td[ng-hide*="includes('manager')"] { @include media($mobile-menu-break) { order: 6; } }

        td[ng-hide*="includes('recipient')"],
        td[ng-hide*="includes('date')"],
        td[ng-hide*="includes('comments')"] { width: 60%; }

        td[ng-hide*="includes('status')"],
        td[ng-hide*="includes('modified')"],
        td[ng-hide*="includes('actions')"] { width: 40%; }

        @include media($mobile-menu-break) {
          td[ng-hide*="includes('owner')"],
          td[ng-hide*="includes('recipient')"] { width: 32%; }
          td[ng-hide*="includes('date')"],
          td[ng-hide*="includes('manager')"] { width: 30%; }

          td[ng-hide*="includes('status')"],
          td[ng-hide*="includes('comments')"] { width: 18%; }
          td[ng-hide*="includes('modified')"],
          td[ng-hide*="includes('actions')"] { width: 18%; margin-left: 2%; }

        }


        td[ng-hide*="includes('date')"],
        td[ng-hide*="includes('modified')"] {
          font-size: 13px;
          font-weight: 500;
          line-height: 19.5px;
          letter-spacing: 0.25px;

          @include media($mobile-menu-break-max) { padding-top: 0; }
        }

        td[ng-hide*="includes('modified')"] {
          display: flex !important;
          flex-wrap: wrap;

          &:before { flex: 1 0 100%; }
          .template-docs__actions {
            margin-left: auto;
            margin-top: -4px;
          }
        }

        td[ng-hide*="includes('owner')"],
        td[ng-hide*="includes('manager')"] {
          font-size: 13px;
          font-weight: 500;
          line-height: 19px;
          letter-spacing: 0.25px;

          @include media($mobile-menu-break) {
            display: flex !important;

            &:before { flex: 0 1 0; margin-right: 5px; }
          }
        }

        td[ng-hide*="includes('recipient')"] {
          color: #38444B;
          font-size: 15px;
          font-weight: 600;
          letter-spacing: 0.25px;
          line-height: 19px;
        }
        td[ng-hide*="includes('status')"] {
          position: relative;
          font-size: 13px;
          font-weight: 500;
          line-height: 19px;
          letter-spacing: 0.25px;
          display: flex !important;
          flex-wrap: wrap;

          span { order: 9; }

          &[data-td='draft'] { color: #AAB3BE; &:after { background-color: #AAB3BE; } }
          &[data-td='view'],&[data-td='proposed'] { color: $main-color; &:after { background-color: $main-color; } }
          &[data-td='review'] { color: #38444B; &:after { background-color: #38444B; } }
          &[data-td='approved'],&[data-td='archived'] { color: #7ED321; &:after { background-color: #7ED321; } }

          &:after { content: ''; display: block; width: 5px; height: 5px; border-radius: 100%; order: 1; align-self: center; margin-right: 5px; transform: translateY(-2px); }
          .template-docs__actions {
            order: 10;
            margin-left: auto;
            transform: translateY(-2px);
          }
        }

        td[ng-hide*="includes('actions')"] {
          .template-docs__actions {

            [title="Related archive"] {
              border-bottom: 1px solid #38444B;
              color: #38444B;
              font-size: 11px;
              font-weight: 500;
              letter-spacing: 0.25px;
              line-height: 16.5px;
            }
          }
        }

        td[ng-hide*="includes('comments')"] .comments-count {
          .btn__icon { opacity: 0; }
          > span {
            display: flex;
            position: relative;
            min-width: unset;
            align-items: center;
            flex-grow: 1;
            &:after {
              content: '';
              display: block;
              width: 24px;
              height: 24px;
              background: url('#{$svg-path}Icons/Comment Plus.svg') no-repeat center;
              background-size: contain;
              filter: invert(72%) sepia(98%) saturate(4745%) hue-rotate(164deg) brightness(100%) contrast(102%);
              margin-left: 5px;

              @include media($mobile-menu-break) {
                margin-right: -3px;
                margin-left: auto;
              }
            }
          }
        }
      }
    }
  }

  > .template-docs {
    > .template-docs__heading {

      h1{
        display: inline-flex;
        .tooltip__button{
          fill:$main-color;
          margin-top:-1px;
          margin-left:8px;
        }
      }

      [class*="__actions"] {
        width: unset;
        position: fixed;
        bottom: 80px;
        right: 20px;
        z-index: 10;
        flex-direction: column;

        @include media($mobile-menu-break) {
          bottom: unset;
          position: unset;
          flex-direction: row;
          gap: 15px;
        }



        .btn {
          padding: 0;
          position: relative;
          border: unset;
          border-radius: 50%;
          background: #fff;
          box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 30px -15px;
          width: 56px;
          height: 56px;
          text-indent: -1000vw;
          white-space: nowrap;
          overflow: hidden;

          &:before {
            filter: invert(72%) sepia(98%) saturate(4745%) hue-rotate(164deg) brightness(100%) contrast(102%);
            content: '';
            display: block;
            margin: auto;
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }

          &:hover { background: #fff !important; }

          &.disabled {
            pointer-events: none;
            background-color: $grey;
            opacity: 0.75;

            &:before {
              filter: invert(26%) sepia(0%) saturate(1281%) hue-rotate(196deg) brightness(92%) contrast(89%);;
            }
          }
        }

        [ng-if*='hasDirectReport'] .btn:before {
          background-image: url('#{$svg-path}Icons/Users Trend Up.svg');
          position: absolute;
        }
        li.action-btn--meeting{
          order: -2;
          margin: 0;
          .btn:before {
            background-image: url('#{$svg-path}Icons/Calendar Plus.svg');
            position: absolute;
          }
        }
        li.action-btn--add{
          order: -1;
          margin: 0;
          .btn:before {
            background-image: url('#{$svg-path}Icons/Add.svg');
            position: absolute;
          }
        }
      }
    }
  }
}

.template-docs h2 {
  color: #38444B;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 30px;

  svg {
    transform: translateY(-1px);
  }
}

body.myTemplates.archive-page {
  main { overflow: unset; }
  .template-docs__heading {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: -30px -15px 20px;
    padding: 0 10px;
    background: #fff;
    border-bottom: 1px solid rgba(#DDE0E5, 0.9);
    min-height: 80px;
    justify-content: center;
    z-index: 1;

    @include media($mobile-menu-break) {
      background: unset;
      border: unset;
      justify-content: start;
    }

    h1 {
      margin: 0;
      padding: 0;
      color: #38444B;
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 0.25px;

      svg { width: 32px; height: 32px; transform: translateY(1px); }
    }
  }
}

[ng-show="previewDocumentPrompt"] {
  .form-buttons {
    display: flex;
    flex-direction: column;
  }
}

template-revisions {
  display: block;
  height: 100%;
  > [class*='notes'] { @include media($mobile-menu-break) { min-width: 568px; } }
  .template-docs--history {

    table {
      display: block;
      margin: 0;

      thead { display: none; }

      tr {
        display: flex;
        flex-wrap: wrap;
        border-left: 3px solid transparent;
        margin-bottom: 15px;

        &.draft { border-color: #AAB3BE !important; }
        &.view,
        &.shared,
        &.proposed { border-color: $main-color !important; }
        &.review,
        &.reviewed { border-color: #38444B !important; }
        &.approved { border-color: #7ED321 !important; }

        &.draft > td:nth-child(2) > span:before { background-color: #AAB3BE; }
        &.view > td:nth-child(2) > span:before,
        &.shared > td:nth-child(2) > span:before { background-color: $main-color; }
        &.review > td:nth-child(2) > span:before,
        &.reviewed > td:nth-child(2) > span:before { background-color: #38444B; }
        &.approved > td:nth-child(2) > span:before { background-color: #7ED321; }


        &.draft > td:nth-child(2) > span { font-weight: 500; color: #AAB3BE; }
        &.view > td:nth-child(2) > span,
        &.shared > td:nth-child(2) > span { font-weight: 500; color: $main-color; }
        &.review > td:nth-child(2) > span,
        &.reviewed > td:nth-child(2) > span { font-weight: 500; color: #38444B; }
        &.approved > td:nth-child(2) > span { font-weight: 500; color: #7ED321; }

        td {
          border: unset;
          background-color: #fff !important;
          box-sizing: border-box;
          text-align: left;

          &:nth-child(1) { width: 60%; }
          &:nth-child(2) {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            width: 40%;
            > span {text-transform: capitalize; order: 9; display: flex; align-items: center; gap: 6px; }
            .template-megaform__label { width: 100%; }
            a {
              display: block;
              position: absolute;
              bottom: 12px;
              right: 10px;
            }

            > span:before {
              content: '';
              display: block;
              width: 5px;
              height: 5px;
              border-radius: 100%;
            }
          }
          &:nth-child(3) {
            position: relative;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            [class*="__label"] { @include media($mobile-menu-break-max) { margin: 0; } }

            &:before {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: calc(100% - 20px);
              height: 1px;
              transform: translateX(10px);
              background: #EEF0F2;
            }
          }

          @include media($mobile-menu-break) {
            &:nth-child(1) { width: 40%; }
            &:nth-child(2) { width: 30%; }
            &:nth-child(3) { width: 30%; display: block; }
          }
        }
      }
    }
  }
}

.template-docs.archive {
  .template-docs__heading { margin-bottom: 20px; }
  h1 {
    display: flex;
    align-items: center;

    a {
      display: block;
      transform: translateY(-3px);
      margin-right: 12px;

      svg {
        pointer-events: none;
        filter: invert(72%) sepia(98%) saturate(4745%) hue-rotate(164deg) brightness(100%) contrast(102%);
      }
    }
  }
}

.template-megaform__toolbar {
  select { text-indent: 3px; }
  select:required:invalid { color: #AAB3BE; font-style: italic; }
  input {
    &::-webkit-input-placeholder { color: #AAB3BE; font-style: italic; }
    &:-moz-placeholder           { color: #AAB3BE; font-style: italic; }
    &::-moz-placeholder          { color: #AAB3BE; font-style: italic; }
    &:-ms-input-placeholder      { color: #AAB3BE; font-style: italic; }
    &:placeholder                { color: #AAB3BE; font-style: italic; }
  }

}

.empty-table {
  padding: 5px;
  background: #F6F7F8 !important;
  color: #AAB3BE;
  font-weight: 400;
  letter-spacing: 0.25px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  figure {
    position: relative;
    border: 1.5px solid #AAB3BE;
    width: unset;
    border-radius: 50%;
    margin-bottom: 8px;
    &:before {
      content: '';
      width: 32px;
      height: 32px;
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url('#{$svg-path}Icons/Template Fields.svg');
      filter: invert(76%) sepia(10%) saturate(307%) hue-rotate(173deg) brightness(94%) contrast(87%);
    }
  }
}

td.dataTables_empty {
  width: 100% !important;
  border: unset !important;
  padding: 0 !important;
}

table.empty.dataTable thead { display: none; }
.template-megaform__toolbar-item {
  .visibility {
    display: flex;
    i { font-style: unset; margin-left: 5px;}
    @include media($tablet) { > .item + .item { margin-left: 2em; } }
    .trigger {
      display: flex;
      position: relative;
      cursor: pointer;
      &:after {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('#{$svg-path}Icons/Add Sm.svg');
        filter: invert(77%) sepia(6%) saturate(498%) hue-rotate(173deg) brightness(93%) contrast(87%);
        margin-left: 10px;
        transform: translateY(-1px);
      }
    }
    > .item {
      display: flex;
      width: 100%;
      align-items: center;
      > em {
        @include action-text-icon-indicator();
        font-size: 18px;
        transform: translateY(1.5px);
        margin-left: auto;
        @include media($tablet) { transform: translateY(2px); margin-left: 20px; }
      }
    }

    @include media($mobile-menu-break) { display: none; }

  }
  &.visibility .template-chips {
    display: none;
    @include media($mobile-menu-break) { display: flex; }
    em { @include action-text-icon-indicator(); font-size: 18px; margin-left: 0.75em; margin-top: 0; }
  }
}

.note-suggestions__members {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  padding: 0;

  > li {
    margin: 10px 0 0;
    padding: 0;
    font-size: 0.85rem;

    > span { text-align: right; }
  }

  label {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #38444B;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 17px;
  }
}

@include media($tablet-max) {
  .st-dropdown-content.fixed {
    display: flex;
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;

    > * {
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}


body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"] .template-docs__actions,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"] { display: none !important; }

.mobile__template-save {
  [type="submit"].danger {
    background-color: scale-color($error-color, $lightness: 75%) !important;

    .svg-icon { fill: $error-color !important; }
  }
}

@include media($tablet-max) {
  template-layout-performance-transformation-tool {
    display: block;
    .mobile__template-form .mce-content-body {
      min-height: 400px !important;
    }
  }
}

button[ng-click*="deleteTemplateModal"] {
  margin-left: auto;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    @include iconSize();
    fill: #AAB3BE;
  }
}

.template-megaform__toolbar-buttons {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 20px 0 0;
  grid-column: span 2 / span 2;
  background: var(--bg-template);
  margin-bottom: 10px;

  [ng-click*="ReferenceTool"] { margin-right: auto !important; }

  [class*='trigger__btn'],.schedule-meeting-btn {
    border: unset;
    color: unset;
    padding: 0;
    background: unset;
    h6,p{
      margin:0;
    }
    //filter: invert(87%) sepia(13%) saturate(235%) hue-rotate(173deg) brightness(81%) contrast(88%);

    &:hover{
      svg{
        fill: $sec-color;
      }
    }

    svg {
      fill: $main-color;
      pointer-events: none;
      @include iconSize();
    }
  }

  @include media($mobile-menu-break) {
    margin-left: 0;
    margin-right: 0;
  }

  > * + * {
    margin-left: 8px;
  }
}

template-layout-culture-assessment-action-plan {
  .questionnaire-group {
    .question {
      display: flex;
      align-items: center;
      padding-top: 20px;
    }
  }
}

.st-dropdown-trigger {
  &.disabled { pointer-events: none; cursor: unset; }
  template-field {
    display: block;
    pointer-events: none;
  }
}

template-rating {
  display: block;
  min-width: 320px;

  .note-suggestions .body {
    padding:0;
    .body-desc{
      margin:0;
      font-size: 16px;
      padding-bottom: .5rem;
      padding:.5rem 0;
      border-bottom:1px solid #eee;
    }

    ul { padding: 0; margin: 0; }
    li{
      list-style: none;
      padding:3px 12px 3px 22px;
      border-bottom:1px solid #f5f5f5;
      &:nth-child(2n){
        background-color:#f2f2f2;
      }
      @include media($mobile-menu-break) {
        &:hover{
          background:#eee !important;
        }
      }

      margin:0;
      display: flex;
      width:100%;
      align-items: center;
      justify-content: space-between;
    }
    li.selected { color: $main-color; }

    .option {
      cursor: pointer;
      padding: 2px 0;
      display: flex;
      flex-direction: column;
      flex:1;
      width:100%;


      label {
        display: flex;
        pointer-events: none;
        justify-content: space-between;
        flex-grow: 1;
        font-weight: 500;
        font-size: 16px;
        padding-right: 0.5em;
      }
      p{
        font-size: 13px;
        opacity: .9;
        margin:0;
        line-height: 1.15;
      }

    }

    svg { flex-shrink: 0; width: 20px; height: 20px; fill: currentColor; margin-left:10px;}
    .body-desc{
      padding:10px 22px;
    }

  }
}
template-layout-performance-reviewer .question > p { font-weight: 500; }

@include media($mobile-menu-break) {
  .mobile__template-head-clone {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EBEBEB;
    background-color: #fff;
    height: $article-header-height;

    &.collapsed {
      opacity: 1;
      visibility: visible;

      & ~ .mobile__template-header,
      & ~ .template-docs__progress { display: none !important; }
      & ~ .template-megaform__toolbar-buttons {
        padding-top: 0;
        margin-top: -1.8em;
      }

    }

    .template-megaform__header { display: none !important; }

    .template-head-clone {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
    }

    .mobile__template-header {
      opacity: 1 !important;
      visibility: visible !important;
      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "title . . subtitle";
      grid-area: 1 / 1 / 2 / 5;

      .title { grid-area: title; strong { line-height: 1; } }
      .subtitle {
        grid-area: subtitle;
        margin-top: 0;
        flex-direction: column;
        align-items: flex-end;
        gap: 5px;
        [class*="label"],
        [class*="value"] { margin-right: 0; }
      }

    }
    .template-docs__progress {
      grid-area: 1 / 2 / 2 / 4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: unset;
      box-shadow: unset;
      margin: 0;
      padding-bottom: 0;
      padding-top: 0;
      [class*="__title"] { display: none; }
      [class*="__steps"] a { opacity: 1 !important; margin-top: 0 !important; visibility: visible !important; }

      [class*="trigger"] {
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
      }
    }
  }
}

@include media($mobile-menu-break-max) {
  modal-delegate-authority {
    > modal.searching {
      delegate-authority > .flexbox {
        z-index: 99;
        background: $white;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;

        > .form-field,
        .flyout__group-field,
        field-suggestions {
          height: 100%;
        }

        .flyout__field-input {
          display: flex;
          flex-direction: column;
          height: 100%;

          > .loading { top: 1.125em; }
        }
        .s-suggestions {
          position: relative;
          flex-grow: 1;
          max-height: unset;
          top: unset;
        }

        > div:last-child { display: none !important; }
      }
    }
  }
}
